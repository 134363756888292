import { useEffect, useState } from "react";

import { Button } from "./shared/components/Button";
import { Textarea } from "./shared/components/Textarea";
import RequestAPI from "./api/request";

import { StarBlank, StarGold, StarRed } from "./shared/components/Icons";

import "./index.css";
import styles from "./styles/satisfaction-survey.module.scss";

import classNames from "classnames/bind";
import { Loading } from "./containers/Loading";
import { SurveySuccess } from "./containers/SurveySuccess";
const css = classNames.bind(styles);

const types = {
  SERVICE: "service",
  ENVIRONMENT: "environment",
  ORDER: "order",
  DELIVERY: "delivery",
  EXPERIENCE: "experience",
};

const serviceTags = [
  "Educação", "Paciência", "Comunicação", "Velocidade", "Simpatia", "Disponibilidade", "Sugestões"
];

const environment = ["Limpeza", "Conforto", "Decoração"]

const orderTags = [
  "Sabor",
  "Tempero",
  "Custo-benefício",
  "Aparência",
  "Quantidade",
  "Embalagem",
  "Temperatura",
];
const deliveryTags = ["Prazo", "Paciência", "Educação", "Cuidado com pedido"];

const experienceTags = [
  "Facilidade",
  "Simplicidade",
  "Coerência",
  "Velocidade",
  "Aplicativo",
  "Sugestões"
];

function App() {
  const [appInitialized, setAppInitialized] = useState(false);
  const [payload, setPayload] = useState({
    service: {
      evaluate: null,
      items: [],
    },
    order: {
      evaluate: null,
      items: [],
    },
    delivery: {
      evaluate: null,
      items: [],
    },
    environment: {
      evaluate: null,
      items: [],
    },
    experience: {
      evaluate: null,
      items: [],
    },
  });

  const [envConfigs, setEnvConfigs] = useState({
    name: null,
    logo: null
  });

  const [collapsed, setCollapsed] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [displayId, setDisplayId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [typeShow, setTypeShow] = useState(true);

  const loadOrderAndEvaluation = async () => {
    const idOrder = document.location.href.split("/").pop();
    const request = new RequestAPI();
    const { response } = (await request.get(`/benefits/orders/${idOrder}/evaluation`)) || {};
    return response.code !== 200 ? null : response.data;
  }

  const init = async () => {
    try {
      const hexId = document.location.href.split("/").pop();
      const { evaluation, type, environment, brand, display_id } = (await loadOrderAndEvaluation()) || {};

      const logo = brand?.logo || environment?.logo;
      const name = brand?.name || environment?.name;

      setAppInitialized(true);
      setEnvConfigs({
        name,
        logo
      });
      setOrderId(hexId);
      setDisplayId(display_id || null);
      setSubmitted(!!evaluation);
      setTypeShow(type === "INDOOR");

    } catch (err) {
      console.log(err);
      setSubmitted(true);
    }
  };

  useEffect(() => {
    if (!appInitialized) {
      init();
    }
  }, [appInitialized]);

  useEffect(() => {
    const loadFavicon = () => {
      const favicon = document.getElementById('favicon');
  
      if (favicon) {
        favicon.href = envConfigs?.logo;
      }
    }

    loadFavicon();
  }, [envConfigs])

  useEffect(() => {
    const orderId = document.location.href.split("/").pop();
    setOrderId(orderId);
    const serviceRating = payload.service.evaluate;
    const orderRating = payload.order.evaluate;
    const deliveryRating = payload.delivery.evaluate;
    const environmentRating = payload.environment.evaluate;
    const experienceRating = payload.experience.evaluate;

    setDisabled(!(serviceRating || (experienceRating && orderRating && deliveryRating) || environmentRating));
  }, [payload]);

  const setTags = (type, tag) => {
    let tagsArr = payload[type].items;
    const index = tagsArr.indexOf(tag);

    if (index > -1) tagsArr.splice(tagsArr.indexOf(tag), 1);
    else tagsArr = [...tagsArr, tag];

    setPayload((payload) => ({
      ...payload,
      [type]: {
        ...payload[type],
        items: tagsArr,
      },
    }));
  };

  const sendSatisfaction = async () => {
    setLoading(true);
    payload.order_id = orderId;
    const request = new RequestAPI();
    const response = await request.post(`/benefits/orders/${orderId}/evaluation`, payload);
    if (response.response.code === 200) {
      setSubmitted(true);
      setLoading(false);
    }
  };

  const isSelected = (type, tag) => {
    return payload[type].items.indexOf(tag) > -1;
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [submitted]);

  return (
    <>
      {loading && (<Loading />)}
      {!loading && !typeShow && (
        <div className={styles.satisfactionSurvey}>
          {/* Delivery, Retirada e Totem-Levar */}
          {!submitted && !loading && (
            <>
              <div className="d-flex align-center">
                <div className={styles.storeLogo}>
                  <img src={envConfigs?.logo} alt="Logo" />
                </div>
                <div className="d-flex flex-col">
                  <p className={styles.storeName}>{envConfigs?.name}</p>
                  <span className={styles.orderNumber}>
                    Avaliação Pedido # {displayId || orderId}
                  </span>
                </div>
              </div>

              <div className={styles.appreciate}>
                <p>
                  O que você achou de pedir conosco?
                </p>
              </div>

              <div className={styles.categories}>
                <div className={styles.category}>
                  <p className={styles.label}>Produto</p>
                  <div className={styles.stars}>
                    {[1, 2, 3, 4, 5].map((value) => {
                      const rating = payload?.order?.evaluate;

                      return (
                        <div
                          className={css({
                            star: true,
                            good: rating >= value && rating > 2,
                            bad: rating >= value && rating <= 2,
                          })}
                          key={value}
                          onClick={() => {
                            setPayload((payload) => ({
                              ...payload,
                              [types.ORDER]: {
                                ...payload[types.ORDER],
                                evaluate: value,
                              },
                            }));

                            const exists = collapsed.includes(types.ORDER);

                            if (!exists)
                              setCollapsed([...collapsed, types.ORDER]);
                          }}
                        >
                          {rating >= value && rating > 2 && <StarGold />}
                          {rating >= value && rating <= 2 && <StarRed />}
                          {!(rating >= value) && <StarBlank />}
                        </div>
                      );
                    })}
                  </div>

                  {collapsed.includes(types.ORDER) && (
                    <>
                      <p className={styles.sublabel}>Do que você gostou?</p>
                      <div className={styles.tags}>
                        {orderTags.map((tag) => {
                          return (
                            <div
                              className={`${styles.tag} ${isSelected(types.ORDER, tag)
                                ? styles.selected
                                : styles.notSelected
                                }`}
                              key={tag}
                              onClick={() => setTags(types.ORDER, tag)}
                            >
                              {tag}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.category}>
                  <p className={styles.label}>Experiência</p>
                  <div className={styles.stars}>
                    {[1, 2, 3, 4, 5].map((value) => {
                      const rating = payload?.experience?.evaluate;

                      return (
                        <div
                          className={css({
                            star: true,
                            good: rating >= value && rating > 2,
                            bad: rating >= value && rating <= 2,
                          })}
                          key={value}
                          onClick={() => {
                            setPayload((payload) => ({
                              ...payload,
                              [types.EXPERIENCE]: {
                                ...payload[types.EXPERIENCE],
                                evaluate: value,
                              },
                            }));

                            const exists = collapsed.includes(types.EXPERINCE);

                            if (!exists)
                              setCollapsed([...collapsed, types.EXPERIENCE]);
                          }}
                        >
                          {rating >= value && rating > 2 && <StarGold />}
                          {rating >= value && rating <= 2 && <StarRed />}
                          {!(rating >= value) && <StarBlank />}
                        </div>
                      );
                    })}
                  </div>

                  {collapsed.includes(types.EXPERIENCE) && (
                    <>
                      <p className={styles.sublabel}>O que podemos melhorar?</p>
                      <div className={styles.tags}>
                        {experienceTags.map((tag) => {
                          return (
                            <div
                              className={`${styles.tag} ${isSelected(types.EXPERIENCE, tag)
                                ? styles.selected
                                : styles.notSelected
                                }`}
                              key={tag}
                              onClick={() => setTags(types.EXPERIENCE, tag)}
                            >
                              {tag}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>

                <div className={styles.category}>
                  <p className={styles.label}>Entrega</p>
                  <div className={styles.stars}>
                    {[1, 2, 3, 4, 5].map((value) => {
                      const rating = payload?.delivery?.evaluate;

                      return (
                        <div
                          className={css({
                            star: true,
                            good: rating >= value && rating > 2,
                            bad: rating >= value && rating <= 2,
                          })}
                          key={value}
                          onClick={() => {
                            setPayload((payload) => ({
                              ...payload,
                              [types.DELIVERY]: {
                                ...payload[types.DELIVERY],
                                evaluate: value,
                              },
                            }));

                            const exists = collapsed.includes(types.DELIVERY);

                            if (!exists)
                              setCollapsed([...collapsed, types.DELIVERY]);
                          }}
                        >
                          {rating >= value && rating > 2 && <StarGold />}
                          {rating >= value && rating <= 2 && <StarRed />}
                          {!(rating >= value) && <StarBlank />}
                        </div>
                      );
                    })}
                  </div>

                  {collapsed.includes(types.DELIVERY) && (
                    <>
                      <p className={styles.sublabel}>Do que você gostou?</p>
                      <div className={styles.tags}>
                        {deliveryTags.map((tag) => {
                          return (
                            <div
                              className={`${styles.tag} ${isSelected(types.DELIVERY, tag)
                                ? styles.selected
                                : styles.notSelected
                                }`}
                              key={tag}
                              onClick={() => setTags(types.DELIVERY, tag)}
                            >
                              {tag}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <p className={styles.label}>
                Gostaria de deixar algum comentário?
              </p>

              <Textarea
                placeholder="Comente aqui"
                onChange={(e) =>
                  setPayload((payload) => ({
                    ...payload,
                    obs: e.target.value,
                  }))
                }
                max={250}
              />

              <span className={styles.maxChar}>{payload?.obs?.length || 0}/250</span>

              <Button
                disabled={disabled}
                className="mt-15"
                onClick={() => sendSatisfaction()}
              >
                Enviar avaliação
              </Button>
            </>
          )}

          {submitted && !loading && (
            <SurveySuccess envName={envConfigs?.name} envLogo={envConfigs?.logo} orderId={orderId} displayId={displayId} />
          )}
        </div>
      )}
      {!loading && typeShow && (
        <div className={styles.satisfactionSurvey}>
          {/* Balcão, Olga Pay e Totem-Aqui */}
          {!submitted && !loading && (
            <>
              <div className="d-flex align-center">
                <div className={styles.storeLogo}>
                  <img src={envConfigs?.logo} alt="Logo" />
                </div>
                <div className="d-flex flex-col">
                  <p className={styles.storeName}>{envConfigs?.name}</p>
                  <span className={styles.orderNumber}>
                    Avaliação Pedido # {displayId || orderId}
                  </span>
                </div>
              </div>

              <div className={styles.appreciate}>
                <p>
                  O que você achou de pedir conosco?
                </p>
              </div>

              <div className={styles.categories}>
                <div className={styles.category}>
                  <p className={styles.label}>Produto</p>
                  <div className={styles.stars}>
                    {[1, 2, 3, 4, 5].map((value) => {
                      const rating = payload?.order?.evaluate;

                      return (
                        <div
                          className={css({
                            star: true,
                            good: rating >= value && rating > 2,
                            bad: rating >= value && rating <= 2,
                          })}
                          key={value}
                          onClick={() => {
                            setPayload((payload) => ({
                              ...payload,
                              [types.ORDER]: {
                                ...payload[types.ORDER],
                                evaluate: value,
                              },
                            }));

                            const exists = collapsed.includes(types.ORDER);

                            if (!exists)
                              setCollapsed([...collapsed, types.ORDER]);
                          }}
                        >
                          {rating >= value && rating > 2 && <StarGold />}
                          {rating >= value && rating <= 2 && <StarRed />}
                          {!(rating >= value) && <StarBlank />}
                        </div>
                      );
                    })}
                  </div>

                  {collapsed.includes(types.ORDER) && (
                    <>
                      <p className={styles.sublabel}>Do que você gostou?</p>
                      <div className={styles.tags}>
                        {orderTags.map((tag) => {
                          return (
                            <div
                              className={`${styles.tag} ${isSelected(types.ORDER, tag)
                                ? styles.selected
                                : styles.notSelected
                                }`}
                              key={tag}
                              onClick={() => setTags(types.ORDER, tag)}
                            >
                              {tag}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.category}>
                  <p className={styles.label}>Atendimento</p>
                  <div className={styles.stars}>
                    {[1, 2, 3, 4, 5].map((value) => {
                      const rating = payload?.service?.evaluate;

                      return (
                        <div
                          className={css({
                            star: true,
                            good: rating >= value && rating > 2,
                            bad: rating >= value && rating <= 2,
                          })}
                          key={value}
                          onClick={() => {
                            setPayload((payload) => ({
                              ...payload,
                              [types.SERVICE]: {
                                ...payload[types.SERVICE],
                                evaluate: value,
                              },
                            }));

                            const exists = collapsed.includes(types.SERVICE);

                            if (!exists)
                              setCollapsed([...collapsed, types.SERVICE]);
                          }}
                        >
                          {rating >= value && rating > 2 && <StarGold />}
                          {rating >= value && rating <= 2 && <StarRed />}
                          {!(rating >= value) && <StarBlank />}
                        </div>
                      );
                    })}
                  </div>

                  {collapsed.includes(types.SERVICE) && (
                    <>
                      <p className={styles.sublabel}>O que podemos melhorar?</p>
                      <div className={styles.tags}>
                        {serviceTags.map((tag) => {
                          return (
                            <div
                              className={`${styles.tag} ${isSelected(types.SERVICE, tag)
                                ? styles.selected
                                : styles.notSelected
                                }`}
                              key={tag}
                              onClick={() => setTags(types.SERVICE, tag)}
                            >
                              {tag}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>

                <div className={styles.category}>
                  <p className={styles.label}>Ambiente</p>
                  <div className={styles.stars}>
                    {[1, 2, 3, 4, 5].map((value) => {
                      const rating = payload?.environment?.evaluate;

                      return (
                        <div
                          className={css({
                            star: true,
                            good: rating >= value && rating > 2,
                            bad: rating >= value && rating <= 2,
                          })}
                          key={value}
                          onClick={() => {
                            setPayload((payload) => ({
                              ...payload,
                              [types.ENVIRONMENT]: {
                                ...payload[types.ENVIRONMENT],
                                evaluate: value,
                              },
                            }));

                            const exists = collapsed.includes(types.ENVIRONMENT);

                            if (!exists)
                              setCollapsed([...collapsed, types.ENVIRONMENT]);
                          }}
                        >
                          {rating >= value && rating > 2 && <StarGold />}
                          {rating >= value && rating <= 2 && <StarRed />}
                          {!(rating >= value) && <StarBlank />}
                        </div>
                      );
                    })}
                  </div>

                  {collapsed.includes(types.ENVIRONMENT) && (
                    <>
                      <p className={styles.sublabel}>Do que você gostou?</p>
                      <div className={styles.tags}>
                        {environment.map((tag) => {
                          return (
                            <div
                              className={`${styles.tag} ${isSelected(types.ENVIRONMENT, tag)
                                ? styles.selected
                                : styles.notSelected
                                }`}
                              key={tag}
                              onClick={() => setTags(types.ENVIRONMENT, tag)}
                            >
                              {tag}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <p className={styles.label}>
                Gostaria de deixar algum comentário?
              </p>

              <Textarea
                placeholder="Comente aqui"
                onChange={(e) =>
                  setPayload((payload) => ({
                    ...payload,
                    obs: e.target.value,
                  }))
                }
              />

              <Button
                disabled={disabled}
                className="mt-15"
                onClick={() => sendSatisfaction()}
              >
                Enviar avaliação
              </Button>
            </>
          )}

          {submitted && !loading && (
            <SurveySuccess envName={envConfigs?.name} envLogo={envConfigs?.logo} orderId={orderId} displayId={displayId} />
          )}
        </div>
      )}

    </>
  );
}

export default App;
