import styles from "../styles/satisfaction-survey.module.scss";
import { TailSpin } from "react-loader-spinner";

export const Loading = () => {
    return (
        <div className={styles.loader}>
            <TailSpin
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}