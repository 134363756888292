export const StarBlank = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99916 0C6.2083 0 6.39925 0.123069 6.49018 0.316933L8.04964 3.62879L11.5346 4.16092C11.7392 4.19139 11.9074 4.33908 11.9733 4.54068C12.037 4.74462 11.9847 4.96732 11.8392 5.115L9.31131 7.69829L9.90917 11.3435C9.94327 11.5545 9.85916 11.7701 9.68867 11.8944C9.52045 12.0186 9.27721 12.035 9.11353 11.9342L5.99916 10.2183L2.8666 11.9342C2.70292 12.035 2.48014 12.0186 2.30965 11.8944C2.14143 11.7701 2.05731 11.5545 2.07095 11.3435L2.68701 7.69829L0.161638 5.115C0.0147848 4.96732 -0.037273 4.74462 0.0272877 4.54068C0.0916212 4.33908 0.261434 4.19139 0.465801 4.16092L3.94867 3.62879L5.50813 0.316933C5.60134 0.123046 5.79002 0 5.99916 0ZM5.99916 1.85073L4.80569 4.3883C4.72613 4.55474 4.57155 4.67195 4.39423 4.70008L1.7045 5.10797L3.65769 7.10286C3.78272 7.23179 3.83956 7.41698 3.81 7.59749L3.3508 10.4011L5.74228 9.0837C5.90368 8.99462 6.09691 8.99462 6.25604 9.0837L8.64751 10.4011L8.18831 7.59749C8.15876 7.41698 8.21786 7.23179 8.34289 7.10286L10.2956 5.10797L7.60636 4.70008C7.42677 4.67195 7.27219 4.55474 7.1949 4.3883L5.99916 1.85073Z"
      fill="#BDBDBD"
    />
  </svg>
);

export const StarRed = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.20725 0.421828C7.07677 0.164044 6.80104 0 6.49823 0C6.19542 0 5.92216 0.164044 5.78922 0.421828L4.20624 3.52226L0.671015 4.01908C0.375592 4.06126 0.129406 4.25812 0.0383175 4.52762C-0.0527713 4.79712 0.0210844 5.09474 0.232804 5.29394L2.79806 7.71007L2.19244 11.1245C2.14321 11.4058 2.2663 11.6917 2.51002 11.858C2.75375 12.0244 3.07625 12.0455 3.34213 11.9119L6.50069 10.3067L9.65926 11.9119C9.92514 12.0455 10.2476 12.0268 10.4914 11.858C10.7351 11.6893 10.8582 11.4058 10.8089 11.1245L10.2009 7.71007L12.7661 5.29394C12.9778 5.09474 13.0542 4.79712 12.9606 4.52762C12.8671 4.25812 12.6233 4.06126 12.3279 4.01908L8.79022 3.52226L7.20725 0.421828Z"
      fill="#FF502D"
    />
  </svg>
);

export const StarGold = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.20725 0.42182C7.07677 0.164037 6.80104 -7.62939e-06 6.49823 -7.62939e-06C6.19542 -7.62939e-06 5.92216 0.164037 5.78922 0.42182L4.20624 3.52225L0.671015 4.01907C0.375592 4.06126 0.129406 4.25811 0.0383175 4.52761C-0.0527713 4.79711 0.0210844 5.09473 0.232804 5.29393L2.79806 7.71007L2.19244 11.1245C2.14321 11.4057 2.2663 11.6917 2.51002 11.858C2.75375 12.0244 3.07625 12.0455 3.34213 11.9119L6.50069 10.3067L9.65926 11.9119C9.92514 12.0455 10.2476 12.0268 10.4914 11.858C10.7351 11.6893 10.8582 11.4057 10.8089 11.1245L10.2009 7.71007L12.7661 5.29393C12.9778 5.09473 13.0542 4.79711 12.9606 4.52761C12.8671 4.25811 12.6233 4.06126 12.3279 4.01907L8.79022 3.52225L7.20725 0.42182Z"
      fill="url(#paint0_linear_35_976)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_35_976"
        x1="6.5"
        y1="0.235287"
        x2="6.5"
        y2="17.5294"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.411458" stopColor="#FFC741" />
        <stop offset="0.9999" stopColor="#E6A50B" />
        <stop offset="1" stopColor="#FFC741" stopOpacity="0.31" />
      </linearGradient>
    </defs>
  </svg>
);
