
import styles from "../styles/satisfaction-survey.module.scss";
export const SurveySuccess = ({ envName, envLogo, orderId, displayId }) => {
    return (
        <div className={styles.success}>
            <div className="d-flex align-center">
                <div className={styles.storeLogo}>
                    <img src={envLogo} alt="Logo" />
                </div>
                <div className="d-flex flex-col">
                    <p className={styles.storeName}>{envName}</p>
                    <span className={styles.orderNumber}>
                        Avaliação Pedido #{displayId || orderId}
                    </span>
                </div>
            </div>

            <div className="d-flex flex-col align-center">
                <div className={styles.star}>
                    <img src="/star.png" alt="" />
                </div>
                <p className={styles.thankYou}>
                    Obrigado por nos enviar seu feedback!
                </p>
            </div>
            {/* <Button className="mt-20" onClick={() => {}}> Botão CTA </Button>*/}
        </div>
    )
}