import axios from "axios";

class RequestAPI {

	environmentId = null;
	/**
	 * @type {import("axios").AxiosInstance}
	 */
	publicInstance = null;
	/**
	 * @type {import("axios").AxiosInstance}
	 */
	privateInstance = null;

	constructor(environmentId) {
		this.environmentId = environmentId;
		this.publicInstance = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			headers: { "Content-Type": "application/json" },
		});
		this.privateInstance = axios.create({
			baseURL: process.env.REACT_APP_PRIVATE_BASE_URL,
			headers: { "Content-Type": "application/json" },
		});
	}

	async get(url, params = {}) {
		try {
			const response = await this.publicInstance.get(url, { params });
			return response.data;
		} catch (error) {
			console.error("GET request error:", error);
			throw error;
		}
	}

	async post(url, data = {}) {
		try {
			const response = await this.publicInstance.post(url, data);
			return response.data;
		} catch (error) {
			console.error("POST request error:", error);
			throw error;
		}
	};

	async getEnvironmentConfigs() {
		try {
			const response = await this.privateInstance.get(`/environments/${this.environmentId}/configurations`);
			return response?.data?.data?.wallet;
		} catch (error) {
			console.error("GET request error:", error);
			throw error;
		}
	}
}

export default RequestAPI;
